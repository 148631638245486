let appConfig = {
    base_url: 'https://api.kurrency.demo.e-makuta.com',
    api_prefix: 'https://api.kurrency.demo.e-makuta.com/api',
    username_prefix: 'SC230912094',
    modules: ['home', 'customer', 'guichet', 'account', 'transactions', 'loan'],
    feats_customer: ['acquisition', 'search', 'validation', 'conformity', 'members', 'groups', 'access',],
    feats_guichet: ['cashin-cashout', 'cashpoint', 'cash-collection'],
    feats_account: ['gl-code', 'open', 'search', 'journal', 'bwakisa-carte'],
    feats_transactions: ['operation', 'validation', 'search', 'fiscal', 'multi-currencies'],
    feats_loan: ['products', 'acquisition'],
    menu_layout: '1',
    authenticatedEntryPath: '/home',
    application_name: "Banky",
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'fr',
    enableMock: false,
}

appConfig = { ...appConfig, apiPrefix: `${appConfig.base_url}/api` }

export default appConfig
